:root {
  --tint-gray: #424f68;
  --tint-gray-fade: #B6BBCA;
}
/* Inter */
@font-face {
  font-family: Inter;
  src: url(/public/resources/font/Inter/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url(/public/resources/font/Inter/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url(/public/resources/font/Inter/Inter-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(/public/resources/font/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url(/public/resources/font/Inter/Inter-Bold.ttf);
  font-weight: 700;
}
/* Playfair */

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Playfair;
  src: url(/public/resources/font/Playfair/PlayfairDisplay-Black.ttf);
  font-weight: 900;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   caret-color: #000;
}
*:focus,
button,
input, span {
  box-shadow: none!important;
  outline: none!important
}
#chatbot-chat {
  z-index: 500!important
}
.block-scroll {
  scrollbar-width: none;
}
.block-scroll::-webkit-scrollbar {
  display: none;
}
@media (hover:none) and (pointer: coarse) {
  .block-scroll {
    width: 100vw
  }
}
.booking-calendar.react-calendar--doubleView {
  border: none;
}
.custom-transform {
  opacity: 1!important;
  transform: none!important;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  padding: 0!important;
  border: 1px solid var(--tint-gray);
  border-top: none;
  max-height: 0;
  border-bottom: none;
  will-change: transform
}
.open-dropdown {
  max-height: 500px;
  border-bottom: 1px solid var(--tint-gray);
}
.chakra-modal__content-container {
  height: 100%!important
}
textarea:focus,
input:focus,
button:focus {
  box-shadow: none!important;
  outline: none !important;
}
.my-booking-carousel .alice-carousel__prev-btn, .my-booking-carousel .alice-carousel__next-btn {
  position: absolute;
  width: 30px;
  top: calc(50% - 33px);
  cursor: pointer;
}
.my-booking-carousel .alice-carousel__prev-btn svg, .my-booking-carousel .alice-carousel__next-btn svg {
  color: #fff;
  margin-top: 12px;
}
.my-booking-carousel .alice-carousel__prev-btn {
  left: -20px;
}

.my-booking-carousel .alice-carousel__next-btn {
  right: 0;
}
.find-my-booking-carousel .alice-carousel__prev-btn, .find-my-booking-carousel .alice-carousel__next-btn {
  position: absolute;
  width: 30px;
  top: 25px;
}
.find-my-booking-carousel .alice-carousel__prev-btn svg, .find-my-booking-carousel .alice-carousel__next-btn svg {
  color: #fff;
  margin-top: 12px;
}
.find-my-booking-carousel .alice-carousel__prev-btn {
  left: -20px;
}

.find-my-booking-carousel .alice-carousel__next-btn {
  right: 0;
}
#ssn {
  background-color: #fff;
}

.chakra-checkbox {
  align-items: start!important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.full-center {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  z-index: 900;
}

.init-spinner {
  width: 100px;
  height: 100px;
  border: 5px solid #000;
  border-bottom-color: transparent!important;
  border-right-color: transparent!important;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@media screen and (min-width: 600px) {
  .btn {
    width: auto;
  }
}
@media screen and (max-width: 850px) {
  .general-calendar{
    margin-top: 30px;
  }
  #chatbot-chat .bubble {
    margin-bottom: 40px!important;
  }
}